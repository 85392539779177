

































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Layout from "@/router/layouts/register.vue";
import TokenService from "@/services/token.service";

export default Vue.extend({
  components: { Layout },

  mounted() {
    this.resetVenueData();
    this.track();
  },
  data() {
    return {
      sendLoader: false,
    };
  },
  computed: {
    ...mapGetters("registerModule", {
      getUserData: "GET_USER_DATA",
      getVenueId: "GET_VENUE_ID",
    }),
  },
  methods: {
    ...mapActions("userModule", {
      logoutUser: "LOGOUT",
      loginUser: "LOGIN",
    }),
    ...mapActions("registerModule", {
      resetVenueData: "RESET_VENUE_DATA",
      setUser: "SET_USER",
    }),
    ...mapActions("browserStreamerModule", {
      forceRefreshVenue: "FORCE_REFRESH_VENUE",
    }),
    async goToLogin(): Promise<void> {
      this.sendLoader = true;
      // const loggedIn =
      //   !!TokenService.getToken() && TokenService.getToken() !== "undefined";
      // if (loggedIn) {
      //   this.logoutUser();
      // }

      // this.$router.push("/login");

      const userData = this.getUserData;

      await this.forceRefreshVenue(this.getVenueId);

      const user: object = {
        email: userData.email,
        password: userData.password,
        rememberMe: true,
      };

      this.setUser({});
      await this.loginUser(user);

      this.sendLoader = false;
      this.$router.push("/venues");
    },

    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "6",
      });
    },
  },
});
